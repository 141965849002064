@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base{
  li{
      @apply p-4
  }
  button{
      @apply text-white border bg-indigo-600 border-indigo-600 hover:bg-transparent hover:text-indigo-600 rounded-md
   }
} */
::-webkit-scrollbar {
  display: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  background: white;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: white;
}
.active {
  color: #6c63ff;
}
.nav-item {
  margin: 0 2px 0 2px;
}
